import * as React from "react";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  Chip,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { get } from "../../service/apiService";

function SubscriptionDialog(props: any) {
  const { onClose, open } = props;
  const [data, setData] = React.useState<any>();
  const userData = localStorage.getItem("userProfileDto");
  const userId = userData ? JSON.parse(userData).id : null;
  const [startDate, setStartDate] = React.useState<any>();
  const [endDate, setEndDate] = React.useState<any>();

  const formatDates = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const formattedStartDate = start.toLocaleDateString("ar");
    const formattedEndDate = end.toLocaleDateString("ar");
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  React.useEffect(() => {
    // Your code here
    get(`Profile/%20${userId}/Subscription`)
      .then((res: any) => {
        setData(res);
        formatDates(res.startDate, res.endDate);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleClose = () => {
    onClose();
  };
  const activeStateToArabic = (state: string) => {
    if (state === "Active") {
      return "نشط";
    } else {
      return "غير نشط";
    }
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle id="alert-dialog-title">{"حالة الإشتراك"}</DialogTitle>
      {data && (
        <div className="px-3 d-flex flex-column">
          <div className="mb-2">
            <span className="fw-bold mt">{`الحالة : `} </span>
            <span
              className={
                data.profileStatus === "Active"
                  ? "active-color"
                  : "danger-color"
              }
            >
              {activeStateToArabic(data.profileStatus)}
            </span>
          </div>
          <div className="mb-2">
            <span className="fw-bold ">{`عدد الايام : `}</span>
            <span>{data.days}</span>
          </div>
          <div className="mb-2">
            <span className="fw-bold ">{`تاريخ بداية الاشتراك : `}</span>
            <span>{startDate}</span>
          </div>
          <div className="mb-2">
            <span className="fw-bold ">{`تاريخ انتهاء الاشتراك : `}</span>
            <span>{endDate}</span>
          </div>
        </div>
      )}
      <DialogActions>
        <Button onClick={handleClose}>إغلاق</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SubscriptionDialog;
