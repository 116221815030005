import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { RootState } from "../state/store";
import { useSelector, useDispatch } from "react-redux";
import { post, get } from "../service/apiService";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  handleErrorsAccordingToStatusUserLogin,
  handleErrorsAccordingToStatusUserOtp,
} from "../utils/helperFunctions";
import { setUser } from "../state/user/userSlice";
import { useNavigate } from "react-router-dom";
import { login } from "../state/auth/authSlice";
import TermsAndConditionsDialog from "../components/Dialog/TermsAndConditionsDialog";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);

  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [authCode, setAuthCode] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<number | null>(218);
  const [authCodeValue, setAuthCodeValue] = useState<number | null>(1234);
  const [deviceType, setDeviceType] = useState<string>("Web");
  const [transactionIdentify, setTransactionIdentify] = useState<string>("");
  const [error, setError] = useState<string>();
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  const [termsAndConditionsText, setTermsAndConditionsText] =
    useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    get("/Connex/request-protected-script").then((data: any) => {
      setTransactionIdentify(data.transactionIdentify);
      localStorage.setItem("transactionIdentify", data.transactionIdentify);
      const dcbProtect = data.dcbProtect;
      // Dynamically inject the script into the page
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.text = dcbProtect;
      document.head.appendChild(script);

      // Dispatch custom event
      const event = new Event("DCBProtectRun");
      document.dispatchEvent(event);
      setLoading(false);
    });
    get("/Terms").then((data: any) => {
      setTermsAndConditionsText(data.content);
    });
  }, []);

  const onPhoneSubmit = async () => {
    try {
      const res = await post(`/identity/otp`, {
        phoneNumber: phoneNumber,
        deviceType: deviceType,
        transactionIdentify: transactionIdentify,
      });
      return res;
    } catch (e: any) {
      if (e?.response) {
        setError(handleErrorsAccordingToStatusUserOtp(e.response.status));
      }
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleAccept = () => {
    setTermsAndConditions(true);
    setOpenDialog(false);
  };
  const onSubmit = () => {
    setError("");
    const reqObj = {
      phoneNumber: phoneNumber,
      otp: authCodeValue,
      deviceType: deviceType,
      transactionIdentify: transactionIdentify,
    };
    post(`/identity/login`, reqObj)
      .then((data) => {
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("tokenExpirationUtc", data.tokenExpirationUtc);
        localStorage.setItem(
          "userProfileDto",
          JSON.stringify(data.userProfileDto)
        );
        localStorage.setItem("isSignUpCompleted", data.isSignUpCompleted);
        dispatch(setUser(data.userProfileDto));
        dispatch(login());

        if (data.userProfileDto.isSignUpCompleted === true) {
          navigate("/");
        } else {
          navigate("/survey");
        }
      })
      .catch((e) => {
        if (e.response.data !== "" && e.response.data !== undefined) {
          setError(e.response.data);
        } else
          setError(handleErrorsAccordingToStatusUserLogin(e.response.status));
      });
  };
  const onSignup = () => {
    setError("");
    const signUpObj = {
      phoneNumber: phoneNumber,
      otp: authCodeValue,
      deviceType: deviceType,
      transactionIdentify: transactionIdentify,
    };
    post(`/Identity/signup`, signUpObj)
      .then((data) => {
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("tokenExpirationUtc", data.tokenExpirationUtc);
        localStorage.setItem(
          "userProfileDto",
          JSON.stringify(data.userProfileDto)
        );
        localStorage.setItem("isSignUpCompleted", data.isSignUpCompleted);
        dispatch(setUser(data.userProfileDto));
        dispatch(login());
        if (data.userProfileDto.isSignUpCompleted) {
          navigate("/");
        } else {
          navigate("/survey");
        }
      })
      .catch((e) => {
        if (e.response.data !== "" && e.response.data !== undefined) {
          setError(e.response.data);
        } else
          setError(handleErrorsAccordingToStatusUserLogin(e.response.status));
      });
  };
  const navigateToPrivacyAr = () => {
    window.open("/privacy-ar", "_blank");
  };
  const returnMain = () => {
    setError("");
    setIsLogin(false);
    setIsRegister(false);
    setAuthCode(false);
  };

  const logInConfrim = () => {
    setError("");
    get(`/identity?phoneNumber=${phoneNumber}`)
      .then(() => {
        onPhoneSubmit()
          .then((data) => {
            if (data) setAuthCode(true);
          })
          .catch((e) => {
            if (e.response.data !== "" && e.response.data !== undefined) {
              setError(e.response.data);
            } else
              setError(
                handleErrorsAccordingToStatusUserLogin(e.response.status)
              );
          });
      })
      .catch((e) => {
        if (e.response.data !== "" && e.response.data !== undefined) {
          setError(e.response.data);
        } else
          setError(handleErrorsAccordingToStatusUserLogin(e.response.status));
      });
  };
  const registerConfirm = () => {
    if (!termsAndConditions) {
      setError("الرجاء الموافقة على شروط الاستخدام");
      return;
    }
    setError("");
    get(`/identity?phoneNumber=${phoneNumber}`)
      .then(() => {
        setError("هذا الرقم مستخدم بالفعل");
      })
      .catch((e) => {
        onPhoneSubmit().then((data) => {
          if (data) setAuthCode(true);
        });
      })
      .catch((e) => {
        if (e.response.data !== "" && e.response.data !== undefined) {
          setError(e.response.data);
        } else
          setError(handleErrorsAccordingToStatusUserLogin(e.response.status));
      });
  };

  const radioClicked = () => {
    setTermsAndConditions(!termsAndConditions);
  };

  return (
    <div className="login-container">
      <img className="logo-img" src="logo.png" alt="React Logo" />
      <div className="login-form-container text-white">
        {!isLogin && !isRegister && (
          <>
            <h2 className="text-white">مرحبًا بك في دايت فت!</h2>
            <p className="text-white-50">رحلتك للجسم المثالي تبدأ من هنا...</p>
          </>
        )}
        {isLogin && !authCode && (
          <>
            <h2 className="text-white"> مرحبا بعودتك</h2>
          </>
        )}
        {isRegister && !authCode && (
          <>
            <h2 className="text-white"> مرحبا بك في دايت فت</h2>
          </>
        )}
        {authCode && (
          <>
            <h2 className="text-white">رمز التحقق</h2>
          </>
        )}
        {!loading ? (
          <FormControl className="input-form" dir="ltr">
            {(isLogin || isRegister) && !authCode ? (
              <>
                <TextField
                  variant="outlined"
                  id="my-input"
                  label="رقم الهاتف"
                  aria-describedby="phone-number"
                  className="input-textfield"
                  value={phoneNumber}
                  type="number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPhoneNumber(Number(e.target.value))
                  }
                  color="success"
                  focused
                />
                <FormHelperText id="my-helper-text"></FormHelperText>
              </>
            ) : authCode ? (
              <>
                <TextField
                  variant="outlined"
                  id="my-input"
                  label="رمز التحقق"
                  aria-describedby="auth-code"
                  className="input-textfield"
                  value={authCodeValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setAuthCodeValue(Number(e.target.value))
                  }
                  color="success"
                  focused
                />
                <FormHelperText id="my-helper-text"></FormHelperText>
              </>
            ) : (
              <></>
            )}
            {error && <p className="text-danger">{error}</p>}
            {!isRegister && !isLogin && (
              <Button
                variant="outlined"
                color="success"
                className="my-2 text-white login-btn"
                onClick={() => {
                  setIsRegister(true);
                }}
              >
                إنشاء حساب جديد
              </Button>
            )}
            <Button
              variant="contained"
              color="success"
              className=" my-2 text-white login-btn"
              id="cta_button"
              onClick={() =>
                !isLogin && !isRegister
                  ? setIsLogin(true)
                  : isLogin && !authCode
                  ? logInConfrim()
                  : authCode && isRegister
                  ? onSignup()
                  : authCode && isLogin
                  ? onSubmit()
                  : registerConfirm()
              }
            >
              {authCode
                ? "متابعة"
                : isLogin
                ? "تسجيل الدخول"
                : isRegister
                ? "إنشاء حساب جديد"
                : "تسجيل الدخول"}
            </Button>
            {(isRegister || isLogin) && (
              <Button
                variant="outlined"
                color="success"
                className="my-2 text-white login-btn"
                onClick={() => {
                  returnMain();
                }}
              >
                رجوع
              </Button>
            )}
            <div className="d-flex flex-row justify-content-center align-items-center">
              {isRegister && !authCode && (
                <>
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => setOpenDialog(true)}
                  >
                    اوافق على شروط الاستخدام
                  </Button>
                  <input
                    type="radio"
                    name="card-select"
                    checked={termsAndConditions}
                    onClick={() => radioClicked()}
                    className="radio-conditions"
                  />
                </>
              )}
            </div>
          </FormControl>
        ) : (
          <CircularProgress size="3rem" />
        )}
      </div>
      <div className="social-media-container">
        <IconButton aria-label="facebook" size="large" color="success">
          <FacebookIcon fontSize="inherit" />
        </IconButton>
        <IconButton aria-label="instagram" size="large" color="success">
          <InstagramIcon fontSize="inherit" />
        </IconButton>
        <IconButton aria-label="twitter" size="large" color="success">
          <TwitterIcon fontSize="inherit" />
        </IconButton>
      </div>
      <div>
        <Button
          variant="text"
          color="secondary"
          className="fw-bold"
          onClick={() => navigate("/support")}
        >
          الدعم
        </Button>
        <Button
          variant="text"
          color="secondary"
          className="fw-bold"
          onClick={() => navigateToPrivacyAr()}
        >
          سياسة الخصوصية لدايت فيت
        </Button>
      </div>
      <TermsAndConditionsDialog
        data={termsAndConditionsText}
        open={openDialog}
        onClose={handleClose}
        accept={handleAccept}
      />
    </div>
  );
}
export default Login;
